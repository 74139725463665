import {
  Box,
  Heading,
  VStack,
  HStack,
  Text,
  useToast,
  Button,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import InitiateGame from "./components/InitiateGame";
import Cook from "./components/cook";
import Fish from "./components/fish";
import LevelUp from "./components/levelUp";
import ClaimWormOnGoodFish from "./components/claimWormOnGoodFish";
import ClaimWorms from "./components/claimWorms";
import ClaimBonus from "./components/ClaimBonus";
import Back from "./assets/back.png";
import FishGame from "./utils/FishGame";
import GoodFish from "./utils/GoodFish";
import Fillet from "./utils/Fillet";
import { ethers } from "ethers";

function App() {
  const [worms, setWorms] = useState();
  const [address, setAddress] = useState("");
  const [goodFish, setGoodFish] = useState();
  const [fillet, setFillet] = useState();
  const toast = useToast();
  async function requestAccount() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await provider.listAccounts();
    await provider.send("eth_requestAccounts", []);
    setAddress(accounts[0]);
  }

  const getContract = async () => {
    try {
      const contract = await FishGame();
      return contract;
    } catch (error) {
      console.log("Get Contract Error: ", error);
    }
  };

  const getGoodFishContract = async () => {
    try {
      const contract = await GoodFish();
      return contract;
    } catch (error) {
      console.log("Get Contract Error: ", error);
    }
  };

  const getFilletContract = async () => {
    try {
      const contract = await Fillet();
      return contract;
    } catch (error) {
      console.log("Get Contract Error: ", error);
    }
  };

  const getFisherMan = async () => {
    try {
      if (typeof window.ethereum !== "undefined") {
        const contract = await getContract();
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await provider.listAccounts();
        setAddress(accounts[0]);
        const network = await provider.getNetwork();
        const chainId = network.chainId;
        if (chainId !== 1) {
          toast({
            title: "Wrong Network",
            description: "Please Switch to Ethereum Mainnet",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return;
        }
        const add = accounts[0];
        if (add === undefined) {
          toast({
            title: "Please Connect to Metamask",
            description: "Your Wallet is Not Connected",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return;
        }
        const data = await contract.balanceOf(add, 1);
        const res = ethers.utils.formatEther(data);
        setWorms(res);
        const goodFishContract = await getGoodFishContract();
        const noofFish = await goodFishContract.balanceOf(add);
        const fish = ethers.utils.formatUnits(noofFish, 0);
        setGoodFish(fish);
        const filletContact = await getFilletContract();
        const filletAmount = await filletContact.balanceOf(add);
        const filletRes = ethers.utils.formatEther(filletAmount);
        setFillet(filletRes);
      }
    } catch (error) {
      console.log("get fisherman error", error);
    }
  };

  useEffect(() => {
    getFisherMan();
  }, []);

  return (
    <>
      <Box
        w="100vw"
        bg="#57C4E5"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundImage={Back}
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
        color="#212738"
      >
        <Box>
          {address === "" ? (
            <Button onClick={requestAccount}>Connect Wallet</Button>
          ) : (
            <Text>{address}</Text>
          )}
        </Box>
        <Box
          h="94vh"
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignItems="center"
          color="#212738"
        >
          <VStack>
            <HStack>
              <InitiateGame />
              <ClaimBonus />
              <ClaimWormOnGoodFish />
              <Cook />
            </HStack>
            <HStack>
              <LevelUp />
              <Fish />
            </HStack>
            <HStack>
              <ClaimWorms />
            </HStack>
          </VStack>
        </Box>
        <HStack
          color="white"
          align="center"
          w="100vw"
          justify="space-around"
          pb={18}
          h="6vh"
        >
          <VStack>
            <Heading size="md">No of Good Fish</Heading>
            <Text>{goodFish}</Text>
          </VStack>
          <VStack>
            <Heading size="md">No of Worms</Heading>
            <Text>{worms}</Text>
          </VStack>
          <VStack>
            <Heading size="md">No of Fillet</Heading>
            <Text>{fillet}</Text>
          </VStack>
        </HStack>
      </Box>
    </>
  );
}

export default App;
